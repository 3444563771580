
import Vue from 'vue';

import IcBurgerMenu from '@/assets/svg/ic_burger_menu.svg?inline';
import IcCart from '@/assets/svg/ic_cart.svg?inline';
import IcHeart from '@/assets/svg/ic_heart.svg?inline';
import Logo from '@/assets/svg/logo.svg?inline';
import LogoPro from '@/assets/svg/logo_pro.svg?inline';
import { ESidebarComponentId } from '@/domain/core/a11y/SidebarComponentId.enum';
import { AllCookies } from '@/domain/core/http/Cookie.enum';
import { ERouteName } from '@/domain/core/Routes.enum';
import { MainMenuItem } from '@/infrastructure/core/components/navigation/MainMenuItem.interface';
import { EGtmEventHeader } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { EFeatureFlags } from '@/infrastructure/externalServices/launchDarkly/types';

enum EHeaderBlockType {
  Banner = 'banner',
  Pro = 'pro',
}

export default Vue.extend({
  name: 'HeaderMain',
  components: {
    IcBurgerMenu,
    IcCart,
    IcHeart,
    Logo,
    LogoPro,
  },
  data() {
    return {
      EHeaderBlockType,
      FormLogin: {
        component: () => import('@/components/organisms/form/FormLogin.vue'),
        id: ESidebarComponentId.FormLogin,
      },
      hideAfter: 160,
      isQRCodeAppDownloadModalOpen: false,
      isHidden: false,
      isSticky: false,
      lastScrollPosition: 0,
      scrollOffset: 80,
      SidebarNavigationMain: {
        component: () => import('@/components/molecules/sidebar/SidebarNavigationMain.vue'),
        id: ESidebarComponentId.SidebarNavigationMain,
      },
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const { userCartId: cartId, userId } = this.$cookies.getAll<AllCookies>();
      const dataRequests = [
        this.$accessor.notifications.fetchNotificationsCount({ cartId, userId }),
      ];

      if (this.isUserAuthenticated) {
        dataRequests.push(
          this.$accessor.shop.fetchShopStatus(),
          this.$accessor.wishlist.fetchWishlist(),
        );
      }

      if (this.isUserAdmin) {
        dataRequests.push(
          this.$accessor.selection.fetchTriageContent(),
        );
      }

      await Promise.allSettled(dataRequests);
    } catch (err) {
      this.$errorMonitor.report(err, 'fatal');
    }
  },
  computed: {
    cartPageHref(): string {
      return this.$router.resolve({ name: ERouteName.CheckoutCart }).href;
    },
    isUserAdmin(): boolean {
      return this.$accessor.user.isAdmin;
    },
    isUserAuthenticated(): boolean {
      return this.$accessor.user.authenticated;
    },
    isUserPro(): boolean {
      return this.$accessor.user.isPro;
    },
    mainNavigation(): MainMenuItem[] {
      return this.$accessor.header.navigation;
    },
    navLinkComponent(): string {
      return this.isUserAuthenticated ? 'BaseLink' : 'SidebarToggle';
    },
    isNavigationSidebarOpen(): boolean {
      return this.$accessor.ui.sidebar.currentComponent?.id === ESidebarComponentId.SidebarNavigationMain;
    },
    userCartItemsCount(): number {
      return this.$accessor.notifications.cart.itemsCount;
    },
    shouldDisplayQRCodeAppDownloadModal(): boolean {
      return this.$accessor?.featureFlags[EFeatureFlags.CrmHeaderAppDownloadButton];
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    this.handleScroll();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    closeQRCodeAppDownloadModal(): void {
      this.isQRCodeAppDownloadModalOpen = false;
    },
    openQRCodeAppDownloadModal(): void {
      this.isQRCodeAppDownloadModalOpen = true;
    },
    handleScroll(): void {
      const scrollPosition = window.scrollY;

      if (scrollPosition > this.scrollOffset && (this.$device.isMobile || !this.isSticky)) {
        this.isSticky = true;
      } else if (this.isSticky && scrollPosition <= this.scrollOffset) {
        this.isSticky = false;
      }

      if (this.$device.isMobile) {
        this.isHidden = scrollPosition > this.lastScrollPosition && scrollPosition > this.hideAfter;
      }

      this.lastScrollPosition = scrollPosition;
    },
    handleTracking(type: string, $event: Event): void {
      const { textContent, href } = $event.currentTarget as HTMLAnchorElement;
      let event = null;

      if (type === EHeaderBlockType.Pro) {
        event = EGtmEventHeader.ClickProLink;
      }

      if (event) {
        this.$gtm.push({
          event,
          name: textContent,
          url: href,
        });
      }
    },
  },
});
