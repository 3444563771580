export const SsrCarousel = () => import('../../node_modules/vue-ssr-carousel/index.js' /* webpackChunkName: "components/ssr-carousel" */).then(c => wrapFunctional(c.default || c))
export const BadgeDiscount = () => import('../../components/atoms/badge/BadgeDiscount.vue' /* webpackChunkName: "components/badge-discount" */).then(c => wrapFunctional(c.default || c))
export const BadgeHandmade = () => import('../../components/atoms/badge/BadgeHandmade.vue' /* webpackChunkName: "components/badge-handmade" */).then(c => wrapFunctional(c.default || c))
export const BadgeNegotiable = () => import('../../components/atoms/badge/BadgeNegotiable.vue' /* webpackChunkName: "components/badge-negotiable" */).then(c => wrapFunctional(c.default || c))
export const BadgeProPrice = () => import('../../components/atoms/badge/BadgeProPrice.vue' /* webpackChunkName: "components/badge-pro-price" */).then(c => wrapFunctional(c.default || c))
export const BadgeSoldOut = () => import('../../components/atoms/badge/BadgeSoldOut.vue' /* webpackChunkName: "components/badge-sold-out" */).then(c => wrapFunctional(c.default || c))
export const BadgeSplitPayment = () => import('../../components/atoms/badge/BadgeSplitPayment.vue' /* webpackChunkName: "components/badge-split-payment" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/atoms/button/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const DialogBackdrop = () => import('../../components/atoms/dialog/DialogBackdrop.vue' /* webpackChunkName: "components/dialog-backdrop" */).then(c => wrapFunctional(c.default || c))
export const EditoBlockTyper = () => import('../../components/atoms/edito/EditoBlockTyper.vue' /* webpackChunkName: "components/edito-block-typer" */).then(c => wrapFunctional(c.default || c))
export const BaseLink = () => import('../../components/atoms/link/BaseLink.vue' /* webpackChunkName: "components/base-link" */).then(c => wrapFunctional(c.default || c))
export const MicrodataOffer = () => import('../../components/atoms/microdata/MicrodataOffer.vue' /* webpackChunkName: "components/microdata-offer" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/atoms/modal/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const NotificationDot = () => import('../../components/atoms/notification/NotificationDot.vue' /* webpackChunkName: "components/notification-dot" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderButtonConnect = () => import('../../components/atoms/placeholder/PlaceholderButtonConnect.vue' /* webpackChunkName: "components/placeholder-button-connect" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderCardProduct = () => import('../../components/atoms/placeholder/PlaceholderCardProduct.vue' /* webpackChunkName: "components/placeholder-card-product" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderInputGroup = () => import('../../components/atoms/placeholder/PlaceholderInputGroup.vue' /* webpackChunkName: "components/placeholder-input-group" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderPrice = () => import('../../components/atoms/placeholder/PlaceholderPrice.vue' /* webpackChunkName: "components/placeholder-price" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderProductReview = () => import('../../components/atoms/placeholder/PlaceholderProductReview.vue' /* webpackChunkName: "components/placeholder-product-review" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderSearch = () => import('../../components/atoms/placeholder/PlaceholderSearch.vue' /* webpackChunkName: "components/placeholder-search" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderSearchPopular = () => import('../../components/atoms/placeholder/PlaceholderSearchPopular.vue' /* webpackChunkName: "components/placeholder-search-popular" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderSearchSaved = () => import('../../components/atoms/placeholder/PlaceholderSearchSaved.vue' /* webpackChunkName: "components/placeholder-search-saved" */).then(c => wrapFunctional(c.default || c))
export const ProductDescription = () => import('../../components/atoms/product/ProductDescription.vue' /* webpackChunkName: "components/product-description" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceTag = () => import('../../components/atoms/product/ProductPriceTag.vue' /* webpackChunkName: "components/product-price-tag" */).then(c => wrapFunctional(c.default || c))
export const SearchSubtitle = () => import('../../components/atoms/search/SearchSubtitle.vue' /* webpackChunkName: "components/search-subtitle" */).then(c => wrapFunctional(c.default || c))
export const BaseTooltip = () => import('../../components/atoms/tooltip/BaseTooltip.vue' /* webpackChunkName: "components/base-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TransitionCollapse = () => import('../../components/atoms/transition/TransitionCollapse.vue' /* webpackChunkName: "components/transition-collapse" */).then(c => wrapFunctional(c.default || c))
export const BadgeSellerLevel = () => import('../../components/molecules/badge/BadgeSellerLevel.vue' /* webpackChunkName: "components/badge-seller-level" */).then(c => wrapFunctional(c.default || c))
export const BannerAppPrompt = () => import('../../components/molecules/banner/BannerAppPrompt.vue' /* webpackChunkName: "components/banner-app-prompt" */).then(c => wrapFunctional(c.default || c))
export const BannerMarketingMessage = () => import('../../components/molecules/banner/BannerMarketingMessage.vue' /* webpackChunkName: "components/banner-marketing-message" */).then(c => wrapFunctional(c.default || c))
export const BannerReviewToCollect = () => import('../../components/molecules/banner/BannerReviewToCollect.vue' /* webpackChunkName: "components/banner-review-to-collect" */).then(c => wrapFunctional(c.default || c))
export const BannerSales = () => import('../../components/molecules/banner/BannerSales.vue' /* webpackChunkName: "components/banner-sales" */).then(c => wrapFunctional(c.default || c))
export const BaseBanner = () => import('../../components/molecules/banner/BaseBanner.vue' /* webpackChunkName: "components/base-banner" */).then(c => wrapFunctional(c.default || c))
export const ButtonAddToSelectionTriage = () => import('../../components/molecules/button/ButtonAddToSelectionTriage.vue' /* webpackChunkName: "components/button-add-to-selection-triage" */).then(c => wrapFunctional(c.default || c))
export const ButtonAddToWishlist = () => import('../../components/molecules/button/ButtonAddToWishlist.vue' /* webpackChunkName: "components/button-add-to-wishlist" */).then(c => wrapFunctional(c.default || c))
export const ButtonCheckoutCartGenerateQuote = () => import('../../components/molecules/button/ButtonCheckoutCartGenerateQuote.vue' /* webpackChunkName: "components/button-checkout-cart-generate-quote" */).then(c => wrapFunctional(c.default || c))
export const ButtonCheckoutNavigation = () => import('../../components/molecules/button/ButtonCheckoutNavigation.vue' /* webpackChunkName: "components/button-checkout-navigation" */).then(c => wrapFunctional(c.default || c))
export const ButtonConnectApple = () => import('../../components/molecules/button/ButtonConnectApple.vue' /* webpackChunkName: "components/button-connect-apple" */).then(c => wrapFunctional(c.default || c))
export const ButtonConnectFacebook = () => import('../../components/molecules/button/ButtonConnectFacebook.vue' /* webpackChunkName: "components/button-connect-facebook" */).then(c => wrapFunctional(c.default || c))
export const ButtonConnectGeneric = () => import('../../components/molecules/button/ButtonConnectGeneric.vue' /* webpackChunkName: "components/button-connect-generic" */).then(c => wrapFunctional(c.default || c))
export const ButtonConnectGoogle = () => import('../../components/molecules/button/ButtonConnectGoogle.vue' /* webpackChunkName: "components/button-connect-google" */).then(c => wrapFunctional(c.default || c))
export const ButtonContactSeller = () => import('../../components/molecules/button/ButtonContactSeller.vue' /* webpackChunkName: "components/button-contact-seller" */).then(c => wrapFunctional(c.default || c))
export const ButtonProductNegotiate = () => import('../../components/molecules/button/ButtonProductNegotiate.vue' /* webpackChunkName: "components/button-product-negotiate" */).then(c => wrapFunctional(c.default || c))
export const ButtonSearch = () => import('../../components/molecules/button/ButtonSearch.vue' /* webpackChunkName: "components/button-search" */).then(c => wrapFunctional(c.default || c))
export const ButtonSplitPayment = () => import('../../components/molecules/button/ButtonSplitPayment.vue' /* webpackChunkName: "components/button-split-payment" */).then(c => wrapFunctional(c.default || c))
export const ButtonToggleFilters = () => import('../../components/molecules/button/ButtonToggleFilters.vue' /* webpackChunkName: "components/button-toggle-filters" */).then(c => wrapFunctional(c.default || c))
export const BaseCard = () => import('../../components/molecules/card/BaseCard.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c))
export const CardText = () => import('../../components/molecules/card/CardText.vue' /* webpackChunkName: "components/card-text" */).then(c => wrapFunctional(c.default || c))
export const CardTextRandom = () => import('../../components/molecules/card/CardTextRandom.vue' /* webpackChunkName: "components/card-text-random" */).then(c => wrapFunctional(c.default || c))
export const BaseCarousel = () => import('../../components/molecules/carousel/BaseCarousel.vue' /* webpackChunkName: "components/base-carousel" */).then(c => wrapFunctional(c.default || c))
export const CategoryMerchandisingItems = () => import('../../components/molecules/category/CategoryMerchandisingItems.vue' /* webpackChunkName: "components/category-merchandising-items" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSummaryItemsImages = () => import('../../components/molecules/checkout/CheckoutSummaryItemsImages.vue' /* webpackChunkName: "components/checkout-summary-items-images" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapsible = () => import('../../components/molecules/collapsible/BaseCollapsible.vue' /* webpackChunkName: "components/base-collapsible" */).then(c => wrapFunctional(c.default || c))
export const EditoAppDownloadNotice = () => import('../../components/molecules/edito/EditoAppDownloadNotice.vue' /* webpackChunkName: "components/edito-app-download-notice" */).then(c => wrapFunctional(c.default || c))
export const EditoAppPromoBanner = () => import('../../components/molecules/edito/EditoAppPromoBanner.vue' /* webpackChunkName: "components/edito-app-promo-banner" */).then(c => wrapFunctional(c.default || c))
export const EditoBlockCallToAction = () => import('../../components/molecules/edito/EditoBlockCallToAction.vue' /* webpackChunkName: "components/edito-block-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const EditoCallout = () => import('../../components/molecules/edito/EditoCallout.vue' /* webpackChunkName: "components/edito-callout" */).then(c => wrapFunctional(c.default || c))
export const EditoCommissionBanner = () => import('../../components/molecules/edito/EditoCommissionBanner.vue' /* webpackChunkName: "components/edito-commission-banner" */).then(c => wrapFunctional(c.default || c))
export const EditoHandmadeBanner = () => import('../../components/molecules/edito/EditoHandmadeBanner.vue' /* webpackChunkName: "components/edito-handmade-banner" */).then(c => wrapFunctional(c.default || c))
export const EditoProBanner = () => import('../../components/molecules/edito/EditoProBanner.vue' /* webpackChunkName: "components/edito-pro-banner" */).then(c => wrapFunctional(c.default || c))
export const EditoReviewPick = () => import('../../components/molecules/edito/EditoReviewPick.vue' /* webpackChunkName: "components/edito-review-pick" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchAutocomplete = () => import('../../components/molecules/instantsearch/InstantSearchAutocomplete.vue' /* webpackChunkName: "components/instant-search-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchAutocompleteSuggestions = () => import('../../components/molecules/instantsearch/InstantSearchAutocompleteSuggestions.vue' /* webpackChunkName: "components/instant-search-autocomplete-suggestions" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchCheckboxes = () => import('../../components/molecules/instantsearch/InstantSearchCheckboxes.vue' /* webpackChunkName: "components/instant-search-checkboxes" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchCheckboxesColor = () => import('../../components/molecules/instantsearch/InstantSearchCheckboxesColor.vue' /* webpackChunkName: "components/instant-search-checkboxes-color" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchCheckboxesCountry = () => import('../../components/molecules/instantsearch/InstantSearchCheckboxesCountry.vue' /* webpackChunkName: "components/instant-search-checkboxes-country" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchClearRefinements = () => import('../../components/molecules/instantsearch/InstantSearchClearRefinements.vue' /* webpackChunkName: "components/instant-search-clear-refinements" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchCollapsible = () => import('../../components/molecules/instantsearch/InstantSearchCollapsible.vue' /* webpackChunkName: "components/instant-search-collapsible" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchConfigure = () => import('../../components/molecules/instantsearch/InstantSearchConfigure.vue' /* webpackChunkName: "components/instant-search-configure" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchCurrentRefinements = () => import('../../components/molecules/instantsearch/InstantSearchCurrentRefinements.vue' /* webpackChunkName: "components/instant-search-current-refinements" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchNumericMenu = () => import('../../components/molecules/instantsearch/InstantSearchNumericMenu.vue' /* webpackChunkName: "components/instant-search-numeric-menu" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchPagination = () => import('../../components/molecules/instantsearch/InstantSearchPagination.vue' /* webpackChunkName: "components/instant-search-pagination" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchRange = () => import('../../components/molecules/instantsearch/InstantSearchRange.vue' /* webpackChunkName: "components/instant-search-range" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchRangeGroup = () => import('../../components/molecules/instantsearch/InstantSearchRangeGroup.vue' /* webpackChunkName: "components/instant-search-range-group" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchSuggestions = () => import('../../components/molecules/instantsearch/InstantSearchSuggestions.vue' /* webpackChunkName: "components/instant-search-suggestions" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchToggle = () => import('../../components/molecules/instantsearch/InstantSearchToggle.vue' /* webpackChunkName: "components/instant-search-toggle" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchVirtualSearchBox = () => import('../../components/molecules/instantsearch/InstantSearchVirtualSearchBox.vue' /* webpackChunkName: "components/instant-search-virtual-search-box" */).then(c => wrapFunctional(c.default || c))
export const NavigationBreadcrumbs = () => import('../../components/molecules/navigation/NavigationBreadcrumbs.vue' /* webpackChunkName: "components/navigation-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const NavigationFooter = () => import('../../components/molecules/navigation/NavigationFooter.vue' /* webpackChunkName: "components/navigation-footer" */).then(c => wrapFunctional(c.default || c))
export const NavigationMain = () => import('../../components/molecules/navigation/NavigationMain.vue' /* webpackChunkName: "components/navigation-main" */).then(c => wrapFunctional(c.default || c))
export const NavigationMainButton = () => import('../../components/molecules/navigation/NavigationMainButton.vue' /* webpackChunkName: "components/navigation-main-button" */).then(c => wrapFunctional(c.default || c))
export const NavigationMainLink = () => import('../../components/molecules/navigation/NavigationMainLink.vue' /* webpackChunkName: "components/navigation-main-link" */).then(c => wrapFunctional(c.default || c))
export const NavigationMainSubmenu = () => import('../../components/molecules/navigation/NavigationMainSubmenu.vue' /* webpackChunkName: "components/navigation-main-submenu" */).then(c => wrapFunctional(c.default || c))
export const NavigationSeo = () => import('../../components/molecules/navigation/NavigationSeo.vue' /* webpackChunkName: "components/navigation-seo" */).then(c => wrapFunctional(c.default || c))
export const NavigationSocial = () => import('../../components/molecules/navigation/NavigationSocial.vue' /* webpackChunkName: "components/navigation-social" */).then(c => wrapFunctional(c.default || c))
export const NavigationStepLink = () => import('../../components/molecules/navigation/NavigationStepLink.vue' /* webpackChunkName: "components/navigation-step-link" */).then(c => wrapFunctional(c.default || c))
export const NavigationStepsCheckout = () => import('../../components/molecules/navigation/NavigationStepsCheckout.vue' /* webpackChunkName: "components/navigation-steps-checkout" */).then(c => wrapFunctional(c.default || c))
export const NavigationUser = () => import('../../components/molecules/navigation/NavigationUser.vue' /* webpackChunkName: "components/navigation-user" */).then(c => wrapFunctional(c.default || c))
export const ProductDeliveries = () => import('../../components/molecules/product/ProductDeliveries.vue' /* webpackChunkName: "components/product-deliveries" */).then(c => wrapFunctional(c.default || c))
export const ProductHelpBanner = () => import('../../components/molecules/product/ProductHelpBanner.vue' /* webpackChunkName: "components/product-help-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/molecules/product/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductRating = () => import('../../components/molecules/product/ProductRating.vue' /* webpackChunkName: "components/product-rating" */).then(c => wrapFunctional(c.default || c))
export const ProductRatingStars = () => import('../../components/molecules/product/ProductRatingStars.vue' /* webpackChunkName: "components/product-rating-stars" */).then(c => wrapFunctional(c.default || c))
export const ProductReinsurance = () => import('../../components/molecules/product/ProductReinsurance.vue' /* webpackChunkName: "components/product-reinsurance" */).then(c => wrapFunctional(c.default || c))
export const ProductShop = () => import('../../components/molecules/product/ProductShop.vue' /* webpackChunkName: "components/product-shop" */).then(c => wrapFunctional(c.default || c))
export const ProductStory = () => import('../../components/molecules/product/ProductStory.vue' /* webpackChunkName: "components/product-story" */).then(c => wrapFunctional(c.default || c))
export const ProductTags = () => import('../../components/molecules/product/ProductTags.vue' /* webpackChunkName: "components/product-tags" */).then(c => wrapFunctional(c.default || c))
export const ProductWishlist = () => import('../../components/molecules/product/ProductWishlist.vue' /* webpackChunkName: "components/product-wishlist" */).then(c => wrapFunctional(c.default || c))
export const SearchSaved = () => import('../../components/molecules/search/SearchSaved.vue' /* webpackChunkName: "components/search-saved" */).then(c => wrapFunctional(c.default || c))
export const SelectCurrency = () => import('../../components/molecules/select/SelectCurrency.vue' /* webpackChunkName: "components/select-currency" */).then(c => wrapFunctional(c.default || c))
export const SelectLocale = () => import('../../components/molecules/select/SelectLocale.vue' /* webpackChunkName: "components/select-locale" */).then(c => wrapFunctional(c.default || c))
export const SelectProfileType = () => import('../../components/molecules/select/SelectProfileType.vue' /* webpackChunkName: "components/select-profile-type" */).then(c => wrapFunctional(c.default || c))
export const SelectQuantity = () => import('../../components/molecules/select/SelectQuantity.vue' /* webpackChunkName: "components/select-quantity" */).then(c => wrapFunctional(c.default || c))
export const BaseSidebar = () => import('../../components/molecules/sidebar/BaseSidebar.vue' /* webpackChunkName: "components/base-sidebar" */).then(c => wrapFunctional(c.default || c))
export const SidebarNavigationMain = () => import('../../components/molecules/sidebar/SidebarNavigationMain.vue' /* webpackChunkName: "components/sidebar-navigation-main" */).then(c => wrapFunctional(c.default || c))
export const SidebarNavigationMainSubmenu = () => import('../../components/molecules/sidebar/SidebarNavigationMainSubmenu.vue' /* webpackChunkName: "components/sidebar-navigation-main-submenu" */).then(c => wrapFunctional(c.default || c))
export const SidebarNavigationUser = () => import('../../components/molecules/sidebar/SidebarNavigationUser.vue' /* webpackChunkName: "components/sidebar-navigation-user" */).then(c => wrapFunctional(c.default || c))
export const SidebarNavigationUserAccount = () => import('../../components/molecules/sidebar/SidebarNavigationUserAccount.vue' /* webpackChunkName: "components/sidebar-navigation-user-account" */).then(c => wrapFunctional(c.default || c))
export const SidebarNavigationUserShop = () => import('../../components/molecules/sidebar/SidebarNavigationUserShop.vue' /* webpackChunkName: "components/sidebar-navigation-user-shop" */).then(c => wrapFunctional(c.default || c))
export const SidebarToggle = () => import('../../components/molecules/sidebar/SidebarToggle.vue' /* webpackChunkName: "components/sidebar-toggle" */).then(c => wrapFunctional(c.default || c))
export const BaseTile = () => import('../../components/molecules/tile/BaseTile.vue' /* webpackChunkName: "components/base-tile" */).then(c => wrapFunctional(c.default || c))
export const TileStory = () => import('../../components/molecules/tile/TileStory.vue' /* webpackChunkName: "components/tile-story" */).then(c => wrapFunctional(c.default || c))
export const WishlistBoardsDropdown = () => import('../../components/molecules/wishlist/WishlistBoardsDropdown.vue' /* webpackChunkName: "components/wishlist-boards-dropdown" */).then(c => wrapFunctional(c.default || c))
export const WishlistBoardsDropdownOpener = () => import('../../components/molecules/wishlist/WishlistBoardsDropdownOpener.vue' /* webpackChunkName: "components/wishlist-boards-dropdown-opener" */).then(c => wrapFunctional(c.default || c))
export const WishlistBoardsDropdownOpenerButton = () => import('../../components/molecules/wishlist/WishlistBoardsDropdownOpenerButton.vue' /* webpackChunkName: "components/wishlist-boards-dropdown-opener-button" */).then(c => wrapFunctional(c.default || c))
export const CardProduct = () => import('../../components/organisms/card/CardProduct.vue' /* webpackChunkName: "components/card-product" */).then(c => wrapFunctional(c.default || c))
export const CardProductRow = () => import('../../components/organisms/card/CardProductRow.vue' /* webpackChunkName: "components/card-product-row" */).then(c => wrapFunctional(c.default || c))
export const CarouselCard = () => import('../../components/organisms/carousel/CarouselCard.vue' /* webpackChunkName: "components/carousel-card" */).then(c => wrapFunctional(c.default || c))
export const CarouselProduct = () => import('../../components/organisms/carousel/CarouselProduct.vue' /* webpackChunkName: "components/carousel-product" */).then(c => wrapFunctional(c.default || c))
export const CarouselProductBestDeals = () => import('../../components/organisms/carousel/CarouselProductBestDeals.vue' /* webpackChunkName: "components/carousel-product-best-deals" */).then(c => wrapFunctional(c.default || c))
export const CarouselProductLastViewed = () => import('../../components/organisms/carousel/CarouselProductLastViewed.vue' /* webpackChunkName: "components/carousel-product-last-viewed" */).then(c => wrapFunctional(c.default || c))
export const CarouselProductTailoredSuggestions = () => import('../../components/organisms/carousel/CarouselProductTailoredSuggestions.vue' /* webpackChunkName: "components/carousel-product-tailored-suggestions" */).then(c => wrapFunctional(c.default || c))
export const CarouselThumbnails = () => import('../../components/organisms/carousel/CarouselThumbnails.vue' /* webpackChunkName: "components/carousel-thumbnails" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSummary = () => import('../../components/organisms/checkout/CheckoutSummary.vue' /* webpackChunkName: "components/checkout-summary" */).then(c => wrapFunctional(c.default || c))
export const CollapsibleReinsurance = () => import('../../components/organisms/collapsible/CollapsibleReinsurance.vue' /* webpackChunkName: "components/collapsible-reinsurance" */).then(c => wrapFunctional(c.default || c))
export const EditoBlockCallouts = () => import('../../components/organisms/edito/EditoBlockCallouts.vue' /* webpackChunkName: "components/edito-block-callouts" */).then(c => wrapFunctional(c.default || c))
export const EditoBlockMain = () => import('../../components/organisms/edito/EditoBlockMain.vue' /* webpackChunkName: "components/edito-block-main" */).then(c => wrapFunctional(c.default || c))
export const EditoBlockSelection = () => import('../../components/organisms/edito/EditoBlockSelection.vue' /* webpackChunkName: "components/edito-block-selection" */).then(c => wrapFunctional(c.default || c))
export const EditoBlocks = () => import('../../components/organisms/edito/EditoBlocks.vue' /* webpackChunkName: "components/edito-blocks" */).then(c => wrapFunctional(c.default || c))
export const FooterCategory = () => import('../../components/organisms/footer/FooterCategory.vue' /* webpackChunkName: "components/footer-category" */).then(c => wrapFunctional(c.default || c))
export const FooterMain = () => import('../../components/organisms/footer/FooterMain.vue' /* webpackChunkName: "components/footer-main" */).then(c => wrapFunctional(c.default || c))
export const FormAddress = () => import('../../components/organisms/form/FormAddress.vue' /* webpackChunkName: "components/form-address" */).then(c => wrapFunctional(c.default || c))
export const FormCheckoutVoucher = () => import('../../components/organisms/form/FormCheckoutVoucher.vue' /* webpackChunkName: "components/form-checkout-voucher" */).then(c => wrapFunctional(c.default || c))
export const FormCreditCard = () => import('../../components/organisms/form/FormCreditCard.vue' /* webpackChunkName: "components/form-credit-card" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../components/organisms/form/FormLogin.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormNewsletter = () => import('../../components/organisms/form/FormNewsletter.vue' /* webpackChunkName: "components/form-newsletter" */).then(c => wrapFunctional(c.default || c))
export const FormNewsletterOptinAfterRegister = () => import('../../components/organisms/form/FormNewsletterOptinAfterRegister.vue' /* webpackChunkName: "components/form-newsletter-optin-after-register" */).then(c => wrapFunctional(c.default || c))
export const FormRegister = () => import('../../components/organisms/form/FormRegister.vue' /* webpackChunkName: "components/form-register" */).then(c => wrapFunctional(c.default || c))
export const FormRegisterAfterOneClick = () => import('../../components/organisms/form/FormRegisterAfterOneClick.vue' /* webpackChunkName: "components/form-register-after-one-click" */).then(c => wrapFunctional(c.default || c))
export const FormShopReview = () => import('../../components/organisms/form/FormShopReview.vue' /* webpackChunkName: "components/form-shop-review" */).then(c => wrapFunctional(c.default || c))
export const FormWishlistBoard = () => import('../../components/organisms/form/FormWishlistBoard.vue' /* webpackChunkName: "components/form-wishlist-board" */).then(c => wrapFunctional(c.default || c))
export const HeaderBanners = () => import('../../components/organisms/header/HeaderBanners.vue' /* webpackChunkName: "components/header-banners" */).then(c => wrapFunctional(c.default || c))
export const HeaderCategory = () => import('../../components/organisms/header/HeaderCategory.vue' /* webpackChunkName: "components/header-category" */).then(c => wrapFunctional(c.default || c))
export const HeaderLite = () => import('../../components/organisms/header/HeaderLite.vue' /* webpackChunkName: "components/header-lite" */).then(c => wrapFunctional(c.default || c))
export const HeaderMain = () => import('../../components/organisms/header/HeaderMain.vue' /* webpackChunkName: "components/header-main" */).then(c => wrapFunctional(c.default || c))
export const InputGroupAddressSelector = () => import('../../components/organisms/inputGroup/InputGroupAddressSelector.vue' /* webpackChunkName: "components/input-group-address-selector" */).then(c => wrapFunctional(c.default || c))
export const InputGroupPaymentGiftCard = () => import('../../components/organisms/inputGroup/InputGroupPaymentGiftCard.vue' /* webpackChunkName: "components/input-group-payment-gift-card" */).then(c => wrapFunctional(c.default || c))
export const InputGroupPaymentSplit = () => import('../../components/organisms/inputGroup/InputGroupPaymentSplit.vue' /* webpackChunkName: "components/input-group-payment-split" */).then(c => wrapFunctional(c.default || c))
export const InputGroupPaymentTransfer = () => import('../../components/organisms/inputGroup/InputGroupPaymentTransfer.vue' /* webpackChunkName: "components/input-group-payment-transfer" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchCategoriesFilter = () => import('../../components/organisms/instantsearch/InstantSearchCategoriesFilter.vue' /* webpackChunkName: "components/instant-search-categories-filter" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchFilters = () => import('../../components/organisms/instantsearch/InstantSearchFilters.vue' /* webpackChunkName: "components/instant-search-filters" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchHeader = () => import('../../components/organisms/instantsearch/InstantSearchHeader.vue' /* webpackChunkName: "components/instant-search-header" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchResults = () => import('../../components/organisms/instantsearch/InstantSearchResults.vue' /* webpackChunkName: "components/instant-search-results" */).then(c => wrapFunctional(c.default || c))
export const InstantSearchSSR = () => import('../../components/organisms/instantsearch/InstantSearchSSR.vue' /* webpackChunkName: "components/instant-search-s-s-r" */).then(c => wrapFunctional(c.default || c))
export const ModalAddressSelection = () => import('../../components/organisms/modal/ModalAddressSelection.vue' /* webpackChunkName: "components/modal-address-selection" */).then(c => wrapFunctional(c.default || c))
export const ModalBuyerProtection = () => import('../../components/organisms/modal/ModalBuyerProtection.vue' /* webpackChunkName: "components/modal-buyer-protection" */).then(c => wrapFunctional(c.default || c))
export const ModalNewsletter = () => import('../../components/organisms/modal/ModalNewsletter.vue' /* webpackChunkName: "components/modal-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ModalNewsletterTemporary = () => import('../../components/organisms/modal/ModalNewsletterTemporary.vue' /* webpackChunkName: "components/modal-newsletter-temporary" */).then(c => wrapFunctional(c.default || c))
export const ModalProductNegotiate = () => import('../../components/organisms/modal/ModalProductNegotiate.vue' /* webpackChunkName: "components/modal-product-negotiate" */).then(c => wrapFunctional(c.default || c))
export const ModalProductShopReviews = () => import('../../components/organisms/modal/ModalProductShopReviews.vue' /* webpackChunkName: "components/modal-product-shop-reviews" */).then(c => wrapFunctional(c.default || c))
export const ModalProductZoom = () => import('../../components/organisms/modal/ModalProductZoom.vue' /* webpackChunkName: "components/modal-product-zoom" */).then(c => wrapFunctional(c.default || c))
export const ModalQRCodeAppDownload = () => import('../../components/organisms/modal/ModalQRCodeAppDownload.vue' /* webpackChunkName: "components/modal-q-r-code-app-download" */).then(c => wrapFunctional(c.default || c))
export const ModalSearch = () => import('../../components/organisms/modal/ModalSearch.vue' /* webpackChunkName: "components/modal-search" */).then(c => wrapFunctional(c.default || c))
export const ModalSplitPayment = () => import('../../components/organisms/modal/ModalSplitPayment.vue' /* webpackChunkName: "components/modal-split-payment" */).then(c => wrapFunctional(c.default || c))
export const ModalWishlistBoard = () => import('../../components/organisms/modal/ModalWishlistBoard.vue' /* webpackChunkName: "components/modal-wishlist-board" */).then(c => wrapFunctional(c.default || c))
export const PlaceAutocomplete = () => import('../../components/organisms/place/PlaceAutocomplete.vue' /* webpackChunkName: "components/place-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const PlaceAutocompleteSuggestions = () => import('../../components/organisms/place/PlaceAutocompleteSuggestions.vue' /* webpackChunkName: "components/place-autocomplete-suggestions" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderCarouselProduct = () => import('../../components/organisms/placeholder/PlaceholderCarouselProduct.vue' /* webpackChunkName: "components/placeholder-carousel-product" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderSearchList = () => import('../../components/organisms/placeholder/PlaceholderSearchList.vue' /* webpackChunkName: "components/placeholder-search-list" */).then(c => wrapFunctional(c.default || c))
export const ProductLandingSeo = () => import('../../components/organisms/product/ProductLandingSeo.vue' /* webpackChunkName: "components/product-landing-seo" */).then(c => wrapFunctional(c.default || c))
export const ProductLandingSoldOut = () => import('../../components/organisms/product/ProductLandingSoldOut.vue' /* webpackChunkName: "components/product-landing-sold-out" */).then(c => wrapFunctional(c.default || c))
export const SearchList = () => import('../../components/organisms/search/SearchList.vue' /* webpackChunkName: "components/search-list" */).then(c => wrapFunctional(c.default || c))
export const SearchListPopular = () => import('../../components/organisms/search/SearchListPopular.vue' /* webpackChunkName: "components/search-list-popular" */).then(c => wrapFunctional(c.default || c))
export const SearchListRecent = () => import('../../components/organisms/search/SearchListRecent.vue' /* webpackChunkName: "components/search-list-recent" */).then(c => wrapFunctional(c.default || c))
export const BaseField = () => import('../../components/atoms/form/field/BaseField.vue' /* webpackChunkName: "components/base-field" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/atoms/form/input/BaseSelect.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const InputCheckbox = () => import('../../components/atoms/form/input/InputCheckbox.vue' /* webpackChunkName: "components/input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const InputPassword = () => import('../../components/atoms/form/input/InputPassword.vue' /* webpackChunkName: "components/input-password" */).then(c => wrapFunctional(c.default || c))
export const InputRadio = () => import('../../components/atoms/form/input/InputRadio.vue' /* webpackChunkName: "components/input-radio" */).then(c => wrapFunctional(c.default || c))
export const InputText = () => import('../../components/atoms/form/input/InputText.vue' /* webpackChunkName: "components/input-text" */).then(c => wrapFunctional(c.default || c))
export const NewsletterDetails = () => import('../../components/atoms/form/utils/NewsletterDetails.vue' /* webpackChunkName: "components/newsletter-details" */).then(c => wrapFunctional(c.default || c))
export const RecaptchaNotice = () => import('../../components/atoms/form/utils/RecaptchaNotice.vue' /* webpackChunkName: "components/recaptcha-notice" */).then(c => wrapFunctional(c.default || c))
export const InputGroup = () => import('../../components/molecules/form/input/InputGroup.vue' /* webpackChunkName: "components/input-group" */).then(c => wrapFunctional(c.default || c))
export const InputRatingStars = () => import('../../components/molecules/form/input/InputRatingStars.vue' /* webpackChunkName: "components/input-rating-stars" */).then(c => wrapFunctional(c.default || c))
export const InputSearch = () => import('../../components/molecules/form/input/InputSearch.vue' /* webpackChunkName: "components/input-search" */).then(c => wrapFunctional(c.default || c))
export const InputShopReviewSuggestions = () => import('../../components/molecules/form/input/InputShopReviewSuggestions.vue' /* webpackChunkName: "components/input-shop-review-suggestions" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
