import { render, staticRenderFns } from "./HeaderMain.vue?vue&type=template&id=4f3213fd"
import script from "./HeaderMain.vue?vue&type=script&lang=ts"
export * from "./HeaderMain.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default,NavigationUser: require('/home/runner/work/webapp/webapp/components/molecules/navigation/NavigationUser.vue').default,NotificationDot: require('/home/runner/work/webapp/webapp/components/atoms/notification/NotificationDot.vue').default,SidebarToggle: require('/home/runner/work/webapp/webapp/components/molecules/sidebar/SidebarToggle.vue').default,InstantSearchAutocomplete: require('/home/runner/work/webapp/webapp/components/molecules/instantsearch/InstantSearchAutocomplete.vue').default,ModalQRCodeAppDownload: require('/home/runner/work/webapp/webapp/components/organisms/modal/ModalQRCodeAppDownload.vue').default,BaseButton: require('/home/runner/work/webapp/webapp/components/atoms/button/BaseButton.vue').default,NavigationMain: require('/home/runner/work/webapp/webapp/components/molecules/navigation/NavigationMain.vue').default})
